<template>
  <div class="service-container">
    <div class="banner" :style ="banner">
      <div class="min-size">
        <!-- <div class="router-title">金融服务</div> -->
        <div class="router-info">
          <div>
            湖南银方将围绕“乡村振兴”大主题开展金融服务、大数据技术服务、落地运营服务，成为湖南本土金融服务乡村振兴龙头企业，成为“三农”群体寻求金融服务的首选伙伴。
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <!-- <div id="operation" class="block operation">
        <div style="display: flex;justify-content: center;align-items: center;">
        <div class="min-size">
          <div class="block-title">
            <div class="block-title-bg">OPERATION</div>
            <div class="block-title">
              主营业务
              <el-divider class="title-direct" direction="vertical"></el-divider>
              <div class="title-right">稳健运营 砥砺前行</div>
            </div>
          </div>

          <div class="block-content">
            <img src="@/assets/yf/service-operation.png" style="width:100%" class="service-operation" alt="service-operation">
          </div>
        </div>
        </div>
      </div> -->
      <div id="management" class="block management" :style ="management">
        <div style="height:100%;display: flex;justify-content: center;align-items: center;">
        <div class="min-size">
          <div class="block-title">
            <div class="block-title-bg">MANAGEMENT</div>
            <div class="block-title">
              网商银行普惠金融
              <el-divider class="title-direct" direction="vertical"></el-divider>
              <div class="title-right">310纯信用  3分钟申请，1秒钟放款，0人工干预</div>
            </div>
          </div>

          <div class="block-content">
               <img style="width:80%" src="@/assets/yf/map.png" alt="image">
          </div>
        </div>
        </div>
      </div>
      <div id="services" class="block services">
        <div style="display: flex;justify-content: center;align-items: center;">
        <div class="min-size">
          <div class="block-title">
            <div class="block-title-bg">SERVICES</div>
            <div class="block-title">
              供应链金融服务
              <el-divider class="title-direct" direction="vertical"></el-divider>
              <div class="title-right">更普惠、更便捷、更综合</div>
            </div>
          </div>

          <div class="block-content">
            <div class="item">
              <div class="text" style="width:420px">
                <div class="title">供应链金融产品</div>
                <div class="desc">供应链金融产品是基于品牌商与经销商（代理商/加盟门店）的采购关系，为经销商提供的一款“操作便捷、利率更低、定向支付”的信用支付产品，可缓解经销商采购订货时的资金周转压力，助力品牌商提升销售规模。</div>
              </div>
            </div>

            <div class="item">
              <div class="text" style="width:520px;padding-left:660px;padding-top:13px">
                <div class="title">产品核心价值</div>
                <div class="desc">
                  <p>品牌商：</p>
                  <p>核心收益：回款+销售规模 </p>
                  <p>优势：无需为经销商提供担保，为渠道提供增值服务，提升销售规模、加速回款。</p>
                  <p>经销商：</p>
                  <p>核心收益：资金周转</p>
                  <p>优势：获得纯信用、操作便捷的经营贷款，解决资金周转压力。</p>
                </div>
              </div>
            </div>

            <div class="image1">
              <img style="width:100%" src="@/assets/yf/service-service1.png" alt="image">
            </div>

            <div class="image2">
              <img style="width:100%" src="@/assets/yf/service-service2.png" alt="image">
            </div>
          </div>
        </div>
        </div>
      </div>
        <div id="development" class="block development" :style ="development">
            <div style="height:100%;opacity: 0.8;background: #001529;display: flex;
    justify-content: center;
    align-items: center;">
              <div class="min-size">
                <div class="block-title">
                  <div class="block-title-bg">DEVELOPMENT</div>
                  <div class="block-title">
                    数字乡村
                    <el-divider class="title-direct" direction="vertical"></el-divider>
                    <div class="title-right">推进乡村治理能力现代化</div>
                  </div>
                </div>

                <div class="block-content" style="margin-bottom:20px">
                  <img width="40%" src="@/assets/yf/service-development.png" class="service-development" alt="service-development">
                </div>
              </div>
            </div>
        </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: 'Service',
  components: {
  },
  data () {
    return {
      banner: {
        backgroundImage: 'url(' + require('@/assets/yf/finace-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center'
      },
      management: {
        backgroundImage: 'url(' + require('@/assets/yf/service-management-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center',
      },
      development: {
        backgroundImage: 'url(' + require('@/assets/yf/service-development-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center',
      }
    }
  }
}
</script>

<style lang="less">
.service-container {
  .banner {
    display: flex;
    justify-content: center;
    height: 600px;

    .min-size {
      width: 1200px;
      padding: 152px 0 58px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .router-title {
        font-size: 48px;
        color: #ffffff;
      }

      .router-info {
        margin-top: 107px;
        font-size: 16px;
        color: #ffffff;
        width: 946px;
        height: 220px;
        background: rgba(0,0,0,0.6);
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          width: 764px;
          line-height: 40px;
        }
      }
    }
  }

  .content {

    .block {
      .min-size {
        width: 1200px;

        .block-title {
          display: flex;
          height: 104px;
          padding-bottom: 20px;

          .block-title-bg {
            position: absolute;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            color: rgba(0,0,0,0.03);
            font-size: 75px;
            line-height: 104px;
            font-weight: 700;
          }

          .block-title {
            padding-left: 20px;
            display: flex;
            font-size: 30px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            color: #000000;
            line-height: 40px;
            align-items: center;

            .title-direct {
              height: 30px;
              width: 3px;
              background-color: #000000;
            }

            .title-right {
              font-size: 24px;
              font-weight: 400;
              color: rgba(102,102,102,1);
            }
          }
        }

        .block-content {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }

    .operation {
      height: 960px;
    }

    .management {
      height: 734px;

      .block-title-bg {
        color: rgba(255,255,255,0.1) !important;
      }

      .block-title {
        color: #ffffff !important;

        .title-direct {
          background-color: #ffffff !important;
        }

        .title-right {
          color: #ffffff !important;
        }
      }

      .block-content {
        .item {
          width: 300px;
          height: 272px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .item-icon {
            margin-top: 39px;
            width: 45px;
            height: 45px;
            border: #ffffff solid 1px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .svg-icon {
              font-size: 24px;
            }
          }

          .item-title {
            font-size: 16px;
            font-weight: 700;
            color: #ffffff;
            line-height: 40px;
          }

          .item-feature {
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            line-height: 40px;
          }

          .item-desc {
            padding-top: 13px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(255,255,255,0.8);
            width: 238px;
            display: flex;
            justify-content: center;
          }

          &:nth-child(even) {
            background: rgba(255,110,13,0.7);
          }

          &:nth-child(odd) {
            background: rgba(192,0,0,0.7);
          }
        }
      }
    }

    .services {
      height: 880px;
      background: #f0f0f0;

      .block-content {
        height: 681px;
        position: relative;

        .item {
          width: 100%;
          height: 240px;
          background: #ffffff;
          box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);

          .text {
            width: 396px;

            .title {
              font-size: 16px;
              font-weight: 700;
              color: #000000;
              line-height: 40px;
            }

            .desc {
              font-size: 14px;
              color: #666666;
              line-height: 28px;
              p{
                margin: 0;
              }
            }
          }

          &:nth-child(1) {
            margin-top: 42px;

            .text {
              padding-top: 45px;
              padding-left: 95px;
            }
          }

          &:nth-child(2) {
            margin-top: 117px;
            margin-bottom: 42px;

            .text {
              padding-top: 45px;
              padding-left: 695px;
            }
          }
        }

        .image1 {
          width: 510px;
          height: 324px;
          position: absolute;
          top: 0;
          right: 65px;
        }

        .image2 {
          width: 510px;
          height: 324px;
          position: absolute;
          bottom: 0;
          left: 65px;
        }
      }
    }

    .development {

      .block-title-bg {
        color: rgba(255,255,255,0.1) !important;
      }

      .block-title {
        color: #ffffff !important;

        .title-direct {
          background-color: #ffffff !important;
        }

        .title-right {
          color: #ffffff !important;
        }
      }
    }
  }
}
</style>
